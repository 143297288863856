import { defineComponent, ref, onMounted, computed, getCurrentInstance, watch } from '@vue/composition-api';
import Accordion from '@/components/Accordion.vue';
import Access from '@/components/Access.vue';
import { AuthPopups, SignUpSources } from '@/Authentication/authentication.const';
import TrustPublication from '@/components/TrustPublication.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
import { SITE_URL } from '@/App.vue';
import { useStoryblokBridge } from '@/shared/composables/useStoryblokBridge';
import { useGetHomeVendorPageContentQuery } from '@/generated-types/graphql.types';
import { applyCMSImageFilters } from '@/StoryBlok/helpers';
export default defineComponent({
    components: {
        TrustPublication,
        Accordion,
        Access
    },
    setup(_, context) {
        const { root } = context;
        const instance = getCurrentInstance();
        const { result, refetch } = useGetHomeVendorPageContentQuery({ enabled: true });
        const storyBase = ref(null);
        const story = computed({
            get: () => storyBase.value,
            set: (value) => (storyBase.value = value)
        });
        const heroBlock = computed(() => {
            if (story.value?.content && story.value.content.body) {
                return story.value.content.body.find(el => el.component === 'Vendor hero');
            }
            return null;
        });
        watch(() => result.value, () => {
            if (result.value) {
                storyBase.value = JSON.parse(result.value.get_home_vendor_cms_content.story).story;
            }
        }, { immediate: true });
        /**
         * Watch for locale change and refetch the home page content
         */
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        const questions = [
            'payout',
            'monitoring',
            'offers',
            'booking_request',
            'call',
            'calendars'
        ];
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        if (window.location.search.includes('_storyblok')) {
            // load the bridge only inside of Storyblok Editor
            useStoryblokBridge(story);
            instance.$on('storyPublished', refetch);
        }
        onMounted(() => {
            useMeta({
                title: root.$i18n.t('meta.home_vendor.title_tag'),
                description: root.$i18n
                    .t('meta.home_vendor.description_tag'),
                url: `${SITE_URL}/vendor`,
                noIndex: false
            });
        });
        return {
            heroBlock,
            questions,
            user,
            AuthPopups,
            SignUpSources,
            applyCMSImageFilters,
            useGetLocalizedPath
        };
    }
});
